<div class="row tw-gap-2">
  <mat-icon
    [fontIcon]="iconName"
    [ngClass]="{
    'tw-text-primary': level === CsdSnackbarLevels.INFO,
    'tw-text-error': level === CsdSnackbarLevels.ERROR,
    'tw-text-success': level === CsdSnackbarLevels.SUCCESS,
  }"
  />
  <div class="msg">{{ text }}</div>
</div>

<button (click)="close.emit()" aria-label="Close snackbar notification">
  <mat-icon fontIcon="icon-close" />
</button>
