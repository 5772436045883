<nav
  class="nav"
  *ngVar="isAuthed$ | async as isAuthed"
  [class.nav_empty]="!isAuthed"
>
  <csd-user-data *ngIf="isAuthed" />
  <a aria-label="Go to main page" class="nav__logo" routerLink="/">
    <img src="/assets/cactusdash-logo.svg" alt="CactusDash" />
  </a>
  <button
    class="csd-button_stroked"
    color="secondary"
    *ngIf="isAuthed"
    (click)="onLogout()"
  >
    Logout
  </button>
</nav>
