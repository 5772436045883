<csd-lazy-loading-spinner
  class="tw-fixed tw-w-full tw-top-0 tw-left-0 tw-z-50"
/>
<csd-snackbar class="tw-z-[100000]" aria-label="Snackbar of notifications" />

<csd-nav-default />
<div class="content-wrapper">
  <router-outlet />
</div>
<csd-footer />
