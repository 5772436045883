<footer class="row">
  <a href="https://cactusweb.io" target="_blank" class="link">CactusDash</a>

  <div
    class="row !tw-justify-center tw-gap-3 xs:tw-row-start-3"
    aria-label="Social links"
  >
    <a
      class="csd-button"
      target="_blank"
      rel="noopener"
      aria-label="VK group"
      href="https://vk.com/cactus_web"
    >
      <mat-icon fontIcon="icon-vk" class="!tw-text-[10px]" />
    </a>

    <a
      class="csd-button"
      target="_blank"
      rel="noopener"
      aria-label="Discord public server"
      href="https://discord.gg/DkzbcX9qDN"
    >
      <mat-icon fontIcon="icon-discord" />
    </a>

    <a
      class="csd-button"
      target="_blank"
      rel="noopener"
      aria-label="Twitter acc"
      href="https://twitter.com/CactusDash_"
    >
      <mat-icon fontIcon="icon-twitter" />
    </a>
  </div>

  <a
    href="https://discord.gg/DkzbcX9qDN"
    target="_blank"
    rel="noopener"
    class="link"
  >
    Get a dash
  </a>
</footer>
